.CreateForm {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 5px;
    border: 2px solid rgb(21,32,66);
    background-color: rgb(233, 237, 250);
    max-width: 600px !important;
    padding: 5px;
}

.DisruptionTypeListItem > svg {
    width: 32px;
    height: 32px;
}

.disruption-popover-target > div {
    width: 100%;
}

.disruption-type-btn > svg {
    width: 20px;
    height: 20px;
}

.disruption-type-list {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 16px;
    gap: 8px;
}

