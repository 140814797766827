.SearchContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 620px;
  height: 60px;
  display: flex;
  flex-direction: column;
}

.SearchInputsContainer {
  border: 1px solid rgba(0,0,0,0.5);
  border-left-width: 0; 
  flex-grow: 1;
  background-color: #253668;
  height: 80px;
  border-radius: 0 0 10px 0;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  display: flex;
}

.SearchInputsContainer .FormRow{
  display: flex;
  align-items: center;
}

.SearchContainer .SearchPerformSearch {
  margin-left: 20px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  min-width: 90px;
}

.SearchContainer .SearchPerformSearch:disabled {
  color: rgba(0,0,0,0.4);
  cursor: not-allowed;
}