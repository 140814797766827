@keyframes AnimEnterLeft {
  0% {
    left: -20px;
  }
  100% {
    left: 10px;
  }
}

.DisruptionDetails {
  animation: AnimEnterLeft 0.4s linear;
  right: unset;
  top: 72px;
  left: 10px;
}

.DisruptionDetails .TitleBG {
  background-color: rgb(21,32,66);
  padding: 5px;
}
.DisruptionDetails .Content {
  padding: 5px;
}

.DisruptionDetails .PreWrap {
  white-space: pre-line;
  margin-top: 10px;
  margin-bottom: 15px;
}
