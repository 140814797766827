.EditForm {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 5px;
    border: 2px solid rgb(21,32,66);
    background-color: rgb(233, 237, 250);
    max-width: 600px !important;
    padding: 5px;
}
